:root {
    --blue: #1e90ff;
    --white: #ffffff;
    --black: #000000;
    --callStatusDialed: #2ecc71;
    --callStatusRinging: #e67e22;
    --callStatusPatched: #1abc9c;
    --callStatusHangup: #9b59b6;
    --callStatusStarted: #e74c3c;
}

.pointer{
    cursor: pointer;
}
td.audio-player > div{
    height: 75px !important;
}
.allocated-type{
    width: auto;
}
.allocated-type input{
    cursor: pointer;
}

.p-datepicker table td > span{
    width: 1.5rem !important;
    height: 1.5rem !important;
}

.p-datepicker, .p-column-filter-overlay{
    z-index: 2000 !important;
}

.p-dropdown-panel{
    z-index: 3000 !important;
}

.loader{
    display: flex;
    justify-content: center; /* Align horizontal */
    align-items: center;   
}
.text-centre{
    text-align: center;
}

table.call-status-api-logs{
    border-collapse: collapse;
}
table.call-status-api-logs thead tr th, table.call-status-api-logs tr{
    border-bottom: 1px solid var(--black);
}
table.call-status-api-logs th, table.call-status-api-logs td {
    border-left: 1px solid rgba(0,0,0,0.2);
    border-right: 1px solid rgba(0,0,0,0.2);
}
table.call-status-api-logs th, table.call-status-api-logs td{ /* Added padding for better layout after collapsing */
    padding: 4px 8px;
}
table.call-status-api-logs td{
    max-width: 100px;
    overflow-wrap: break-word;
}

/*Timeline*/
.timeline{
    position: relative;
    margin: 50px auto;
    padding: 40px 0;
    width: 100%;
}

/* .timeline::before{
    content: '';
    position: absolute;
    left: 50%;
    width: 2px;
    height: 100%;
    background-color: #e4e4e4;
} */


/*Elementos de lista*/
.timeline ul li{
    list-style: none;
    position: relative;
    width: 50%;
    padding: 20px 40px;
}

.timeline ul li:nth-child(odd){
    float: left;
    text-align: right;
    clear: both;
}

.timeline ul li:nth-child(even){
    float: right;
    text-align: left;
    clear: both;
}

/*Circulo indicador*/
.timeline ul li .time::before{
    content: '';
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: var(--white);
    background-size: 80%;
    box-shadow: 0 0 0 3px rgba(125, 180, 181, 0.2);
}

.timeline ul li:nth-child(odd) .time::before{
    left: 90%;
}

.timeline ul li:nth-child(even) .time::before{
    left: -9%;
}

.timeline ul li.b_dialed .time::before{
    background-image: url('../images/calls/dialed.png');
    box-shadow: 0 0 0 3px var(--callStatusDialed);
}

.timeline ul li.b_ringing .time::before{
    background-image: url('../images/calls/ringing.png');
    box-shadow: 0 0 0 3px var(--callStatusRinging);
}

.timeline ul li.patched .time::before{
    background-image: url('../images/calls/patched.png');
    box-shadow: 0 0 0 3px var(--callStatusPatched);
}

.timeline ul li.hangup .time::before{
    background-image: url('../images/calls/hangup.png');
    box-shadow: 0 0 0 3px var(--callStatusHangup);
}

.timeline ul li.started .time::before{
    background-image: url('../images/calls/started.png');
    box-shadow: 0 0 0 3px var(--callStatusStarted);
}

.timeline ul li::before{
    content: '';
    position: absolute;
    /* left: 50%; */
    width: 2px;
    height: 100%;
    background-color: var(--callStatusStarted);
}

.timeline ul li:nth-child(odd)::before{
    right: 14px;
}
.timeline ul li:nth-child(even)::before{
    left: -16px;
}

/*Fecha*/
.time{
    display: inline-block;
    font-weight: 400;
    font-size: 14px;
    padding: 5px 10px;
    margin-bottom: 15px;
    background-color: var(--callStatusStarted);
    color: var(--white);
    border-radius: 20px;
    /* box-shadow: 0 0 0 3px rgba(125, 180, 181, 0.2); */
}

ul li.b_dialed::before, ul li.b_dialed .time{
    background-color: var(--callStatusDialed);
}

ul li.b_ringing::before, ul li.b_ringing .time{
    background-color: var(--callStatusRinging);
}

ul li.patched::before, ul li.patched .time{
    background-color: var(--callStatusPatched);
}

ul li.hangup::before, ul li.hangup .time{
    background-color: var(--callStatusHangup);
}

h3{
    font-weight: 400;
}

/*Contenido*/
.content{
    padding-bottom: 20px;
}

.timeline ul li h2{
    font-weight: 500;
    color: #7DB4B5;
}

.timeline ul li p{
    margin: 10px 0;
    font-weight: 300;
}

.modal .modal-body{
    max-height: 500px;
    overflow-y: auto;
}

